import { render, staticRenderFns } from "./AppBrandsCreate.vue?vue&type=template&id=23462e9c&scoped=true&"
import script from "./AppBrandsCreate.vue?vue&type=script&lang=js&"
export * from "./AppBrandsCreate.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23462e9c",
  null
  
)

export default component.exports